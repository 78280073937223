import {SmartHttp} from '../core'
const request = new SmartHttp({
    baseURL: 'http://api.tyjr.ntxzx.cn/public/cmobile',
    disableLoadingAnimation: false,
    timeout: 20000
}).request;
const httpService = {
    // 活动是否开始
    check: () => {
        return request.get("/play/check");
    },
    // 获取单位
    company: () => {
        return request.get("/index/company");
    },
    // 问卷列表
    ques: (params) => {
        return request.get('/play', params);
    },
    // 提交答案
    submit: (params) => {
        return request.post('/play/submit', params);
    },
    // 获取排名
    getTop: (params) => {
        return request.get('/index/top', params);
    },
}
export { request, httpService }
